import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context";
import Zoom from "@mui/material/Zoom";

// Component
import UserSummarySkeleton from "../Skeletons/UserSummarySkeleton";

// MUI

import {
  Cached,
  CachedRounded,
  CloseRounded,
  DoneRounded,
  ThumbDownRounded,
} from "@mui/icons-material";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import TextEditor from "./TextEditor";

function UserSummaryPanel({ loading, genSummary, summaryLoading }) {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);
  const [showaction, setshowaction] = useState(false);

  useEffect(() => {
    if (
      expertClinicalNotesData?.data?.summary?.summary?.trim() === "" ||
      expertClinicalNotesData?.data?.summary?.summary?.replace(
        /&nbsp;/g,
        ""
      ) === "<p></p>\n"
    ) {
      setshowaction(false);
    } else {
      setshowaction(true);
    }
  }, [expertClinicalNotesData?.data?.summary?.summary]);

  return (
    <Fragment>
      {loading || summaryLoading ? (
        <UserSummarySkeleton />
      ) : (
        <div
          className="mt-4 rounded rounded-4  border pb-3 "
          style={{ backgroundColor: "white" }}
        >
          <div className="d-flex align-items-center border-bottom justify-content-between">
            <p className="py-3 px-4 m-0 fw-bold">Summary</p>
            {!expertClinicalNotesData?.data?.submitted &&expertClinicalNotesData?.headerData?.expert_id&&
              expertClinicalNotesData?.data?.summary?.summary === undefined && (
                <Button
                  onClick={() => {
                    genSummary();
                    expertClinicalNotesData.socketRef.current.send(
                      JSON.stringify({
                        action_type: "regenerated",
                        details: {
                          field_name: "Summary",
                        },
                        user_id: expertClinicalNotesData?.headerData?.user_id,
                        expert_id:
                          expertClinicalNotesData?.headerData?.expert_id,
                      })
                    );
                  }}
                  id="regenerate_summary"
                  style={{
                    background: "var(--primary-accent-color)",
                    color: "var(--primary-color)",
                  }}
                  disabled={expertClinicalNotesData?.editTemplate}
                  className="mx-4  rounded-2 font-inter font-w600 border-0"
                >
                  <Cached
                    className={`${summaryLoading ? "animate-spin" : ""} me-2`}
                  />
                  {!expertClinicalNotesData?.data?.summary
                    ? "Generate"
                    : "Regenerate"}
                </Button>
              )}
          </div>

          {expertClinicalNotesData?.data?.summary?.summary === undefined ? (
          expertClinicalNotesData?.data?.summary&&  <>
              {" "}
              <p
                className="mx-2 mx-sm-4 mt-3 fw-bold  p-2"
                style={{ background: "#E9E8E7", borderRadius: "10px" }}
              >
                {expertClinicalNotesData?.data?.summary?.one_line_summary}
              </p>
              <div className="row row-cols-1 row-cols-lg-2 p-2 p-sm-4">
                <div className="d-flex flex-column col  gap-2">
                  <p className="m-0">
                    <span className="fw-bold">Chronic Conditions:</span>{" "}
                    <span>
                      {
                        expertClinicalNotesData?.data?.summary
                          ?.chronic_conditions
                      }
                    </span>
                  </p>
                  <p className="m-0">
                    <span className="fw-bold">Current Medication:</span>{" "}
                    <span>
                      {
                        expertClinicalNotesData?.data?.summary
                          ?.current_medications
                      }
                    </span>
                  </p>{" "}
                  <p className="m-0">
                    <span className="fw-bold">Hospitalizations:</span>{" "}
                    <span>
                      {expertClinicalNotesData?.data?.summary?.hospitalizations}
                    </span>
                  </p>{" "}
                  <p className="m-0">
                    <span className="fw-bold">Surgeries:</span>{" "}
                    <span>
                      {expertClinicalNotesData?.data?.summary?.surgeries}
                    </span>
                  </p>{" "}
                  <p className="m-0">
                    <span className="fw-bold">Recent Tests:</span>{" "}
                    <span>
                      {expertClinicalNotesData?.data?.summary?.recent_tests}
                    </span>
                  </p>{" "}
                </div>
                <div className="d-flex flex-column   col gap-2">
                  <p className="fw-semibold m-0">Psychiatric History:</p>
                  <div className=" rounded-4  p-2 border border-2">
                    <ul className="d-flex flex-column gap-2 overflow-auto">
                      {expertClinicalNotesData?.data?.summary
                        ?.psychiatric_history?.length > 0 &&
                        expertClinicalNotesData?.data?.summary?.psychiatric_history?.map(
                          (item, index) => <li key={index}>{item?.history}</li>
                        )}
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="mx-4  fw-bold d-none p-2"
                style={{ background: "#E9E8E7", borderRadius: "10px" }}
              >
                <p className=" fw-semibold">Notes from Clinician:</p>
                <ul>
                  {expertClinicalNotesData?.data?.summary?.notes_from_clinician
                    ?.length > 0 &&
                    expertClinicalNotesData?.data?.summary?.notes_from_clinician?.map(
                      (item, index) => (
                        <li key={index} className="fw-normal">
                          {item.note}
                        </li>
                      )
                    )}
                </ul>
              </div>
            </>
          ) : (
            <div className="p-2 p-md-4">
              <TextEditor
                disabled={
                  expertClinicalNotesData?.editTemplate ||
                  !expertClinicalNotesData?.headerData?.expert_id ||
                  expertClinicalNotesData?.data?.submitted
                }
                initialText={expertClinicalNotesData?.data?.summary?.summary}
                htmlText={(text) => {
                  setExpertClinicalNotesData((prev) => {
                    return {
                      ...prev,
                      data: {
                        ...prev.data,
                        summary: {
                          ...prev.data.summary,
                          summary: text,
                        },
                      },
                    };
                  });
                }}
              />
              {showaction &&
                expertClinicalNotesData?.data?.summary?.accepted !== "true" &&
                !expertClinicalNotesData?.data?.submitted &&
                !expertClinicalNotesData?.editTemplate &&
                expertClinicalNotesData?.headerData?.expert_id && (
                  <div className="py-2">
                    <ButtonGroup
                      size="medium"
                      variant="outlined"
                      aria-label="Basic button group"
                    >
                      <Tooltip
                        TransitionComponent={Zoom}
                        title="Accept"
                        arrow
                        placement="top"
                      >
                        <Button
                          className="accept border border-1 "
                          sx={{
                            borderTopLeftRadius: "6px",
                            borderBottomLeftRadius: "6px",
                          }}
                          onClick={() => {
                            setExpertClinicalNotesData((prev) => {
                              return {
                                ...prev,
                                data: {
                                  ...prev.data,
                                  summary: {
                                    ...prev.data.summary,
                                    accepted: "true",
                                  },
                                },
                              };
                            });
                          }}
                          id={`accept_summary`}
                          disabled={
                            expertClinicalNotesData?.data?.submitted ||
                            expertClinicalNotesData?.editTemplate
                          }
                        >
                          <DoneRounded style={{ color: "#6c757d" }} />
                        </Button>
                      </Tooltip>

                      <Tooltip
                        TransitionComponent={Zoom}
                        title="Dislike"
                        arrow
                        placement="top"
                      >
                        <Button
                          id={`dislike_summary`}
                          className="dislike  border border-1"
                          disabled={
                            expertClinicalNotesData?.data?.submitted ||
                            expertClinicalNotesData?.editTemplate
                          }
                          onClick={() => {
                            genSummary();
                            expertClinicalNotesData.socketRef.current.send(
                              JSON.stringify({
                                action_type: "regenerated",
                                details: {
                                  field_name: "Summary",
                                },
                                user_id:
                                  expertClinicalNotesData?.headerData?.user_id,
                                expert_id:
                                  expertClinicalNotesData?.headerData
                                    ?.expert_id,
                              })
                            );
                          }}
                        >
                          <ThumbDownRounded style={{ color: "#6c757d" }} />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        TransitionComponent={Zoom}
                        title="Regenerate"
                        arrow
                        placement="top"
                      >
                        <Button
                          id={`regenerate_summary`}
                          className="regenerate border border-1"
                          onClick={() => {
                            genSummary();
                            expertClinicalNotesData.socketRef.current.send(
                              JSON.stringify({
                                action_type: "regenerated",
                                details: {
                                  field_name: "Summary",
                                },
                                user_id:
                                  expertClinicalNotesData?.headerData?.user_id,
                                expert_id:
                                  expertClinicalNotesData?.headerData
                                    ?.expert_id,
                              })
                            );
                          }}
                          disabled={
                            expertClinicalNotesData?.data?.submitted ||
                            expertClinicalNotesData?.editTemplate
                          }
                        >
                          <CachedRounded style={{ color: "#6c757d" }} />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        TransitionComponent={Zoom}
                        title="Clear"
                        arrow
                        placement="top"
                      >
                        <Button
                          sx={{
                            borderTopRightRadius: "6px",
                            borderBottomRightRadius: "6px",
                          }}
                          onClick={() => {
                            setExpertClinicalNotesData((prev) => {
                              return {
                                ...prev,
                                data: {
                                  ...prev.data,
                                  summary: {
                                    ...prev.data.summary,
                                    summary: "",
                                  },
                                },
                              };
                            });
                          }}
                          id={`cleared_summary`}
                          className="cleared  border border-1"
                          disabled={
                            expertClinicalNotesData?.data?.submitted ||
                            expertClinicalNotesData?.editTemplate
                          }
                        >
                          <CloseRounded style={{ color: "#6c757d" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </div>
                )}
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}

export default UserSummaryPanel;
