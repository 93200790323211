import React from "react";

// MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ArchiveOutlined, HelpOutline } from "@mui/icons-material";
import { handleBrokenImage, isAusDomain } from "../lib/utils";

function ClientsCard({ data, handleDelete, handleView, setNoteType }) {
  const [open, setOpen] = React.useState(false);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    return `${day} ${month}, ${year}`;
  }
  return (
    <div className="p-3">
      <div className="clinicalNote--ClientItem d-flex flex-column justify-content-between h-100 p-3">
        <div className="d-flex align-items-center justify-content-between ClientItem--Header">
          <div className="d-flex align-items-center gap-2">
            <img
              alt=""
              src={data?.client?.image}
              onError={(e) => {
                const newLink = handleBrokenImage(data?.client?.image);
                e.target.src = newLink;
              }}
              width={50}
              height={50}
            />
            <div>
              <h2
                className="line-text-clamp "
                style={{ fontWeight: "600", fontSize: "18px" }}
              >
                {data?.client?.name}
              </h2>
              <p className="m-0">{data?.session_type}</p>
            </div>
          </div>
          {data?.status === "In Progress" && (
            <Tooltip
              arrow
              title={
                <p className="m-0" style={{ fontSize: "14px", color: "white" }}>
                  Archive
                </p>
              }
              placement="top-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "common.black",
                    "& .MuiTooltip-arrow": {
                      color: "common.black",
                    },
                    color: "common.white",
                    padding: "10px",
                  },
                },
              }}
            >
              <IconButton
                onClick={() => setOpen(true)}
                style={{ minWidth: "max-content" }}
                className="archive_clinical_note"
              >
                <ArchiveOutlined />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div
         
          className="ClientItem--Content p-1 my-1"
        >
          <div className="d-flex align-items-center gap-1 my-2">
            Consultation Date:
            <span>{data?.session_date && formatDate(data.session_date)}</span>
          </div>

          

          {data?.client?.phone && (
            <div className="my-2">Mobile No: {data?.client?.phone}</div>
          )}
        </div>
        <div className="  ">
          {data?.status !== "Archived" && (
            <button
              style={{
                borderRadius: "12px",
                border:
                  data?.status !== "In Progress"
                    ? "1.245px solid var(--primary-color)"
                    : "1.245px solid #C5C5C5",
                background: "var(--primary-accent-color)",
                color: "var(--primary-color)",
              }}
              className={`p-2 w-100 font-inter fw-semibold lh-base${
                data?.status === "In Progress"
                  ? "in_progress"
                  : "view_clinical_Note"
              }`}
              onClick={() => {
                setNoteType(data?.session_type);
                handleView(data);
              }}
            >
              {data?.status === "In Progress" ? (
                <p
                  className="m-0"
                  style={{
                    color: "var(--Text-text-secondary, #252422)",
                    fontFamily: "Inter",
                    fontSize: "0.9rem",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "130%",
                  }}
                >
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.1364 4.48725L18.8234 2.79925C19.1751 2.44757 19.6521 2.25 20.1494 2.25C20.6468 2.25 21.1237 2.44757 21.4754 2.79925C21.8271 3.15092 22.0247 3.6279 22.0247 4.12525C22.0247 4.62259 21.8271 5.09957 21.4754 5.45125L10.8564 16.0702C10.3277 16.5986 9.67578 16.987 8.95941 17.2002L6.27441 18.0002L7.07441 15.3152C7.28769 14.5989 7.67605 13.9469 8.20441 13.4182L17.1364 4.48725ZM17.1364 4.48725L19.7744 7.12525M18.2744 14.0002V18.7502C18.2744 19.347 18.0374 19.9193 17.6154 20.3412C17.1934 20.7632 16.6212 21.0002 16.0244 21.0002H5.52441C4.92768 21.0002 4.35538 20.7632 3.93342 20.3412C3.51147 19.9193 3.27441 19.347 3.27441 18.7502V8.25025C3.27441 7.65351 3.51147 7.08121 3.93342 6.65926C4.35538 6.2373 4.92768 6.00025 5.52441 6.00025H10.2744"
                      stroke="#252422"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>{" "}
                  Edit Draft
                </p>
              ) : (
                "View Clinical Note"
              )}
            </button>
          )}
        </div>
      </div>
      <Dialog
        className="clinicalnotes-submit-dialog text-center"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogContent>
          <DialogTitle
            style={{
              fontSize: "20px",
              color: "#101828",
              fontWeight: "600",
              paddingTop: "0px",
            }}
          >
            Archive Clinical Note{" "}
            <Tooltip
              arrow
              title={
                <p className="m-0" style={{ fontSize: "14px", color: "white" }}>
                  Once archived, the clinical note cannot be edited.
                </p>
              }
              placement="top-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "common.black",
                    "& .MuiTooltip-arrow": {
                      color: "common.black",
                    },
                    color: "common.white",
                    padding: "10px",
                  },
                },
              }}
            >
              <HelpOutline />
            </Tooltip>
          </DialogTitle>
          <DialogContentText
            id="alert-dialog-title"
            style={{ fontSize: "16px", color: "#344054" }}
          >
            Are you sure you want to archive the clinical note?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="p-4 d-flex gap-3 justify-content-between  pt-0 ">
          <Button
            onClick={() => setOpen(false)}
            className="save font-inter col  "
          >
            Exit
          </Button>
          <Button
            onClick={() => {
              handleDelete(data);
              setOpen(false);
            }}
            id="archived_clinical_note"
            autoFocus
            className="archive m-0  col font-inter "
          >
            Archive
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ClientsCard;
